<template>
  <div v-if="show" class="message">
    <p>{{ props.message }}</p>
    <button @click="emitShow(false)">X</button>
  </div>
</template>

<script lang="ts" setup>
import { defineProps, defineEmits, ComputedRef, computed } from "vue";
const emit = defineEmits(['changeShow'])

// Props
const props = defineProps({ 
    show: { type: Boolean, required: true },
    message: { type: String, required: true },
    success: { type: Boolean, required: true },
});

// Methods
const emitShow = (show: boolean) => emit('changeShow', show)

// Computed 
const messageBackground: ComputedRef<string> = computed(() => props.success ? 'var(--light-green-pecsmart)' : 'var(--error-light-red)');
const messageButtonBackground: ComputedRef<string> = computed(() => props.success ? 'var(--btn-green)' : 'var(--error-lighter-red)');
const messageButtonHoverBackground: ComputedRef<string> = computed(() => props.success ? 'var(--btn-hover-green)' : 'var(--error-dark-red)');
</script>

<style scoped>
.message {
  display: flex;
  position: fixed;
  bottom: 0;
  right: 0;
  background: v-bind(messageBackground);
  color: white;
  border-radius: 12px;
  margin: 12px;
  animation: slide 0.8s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

@keyframes slide {
  0% {
    transform: translateX(500px);
  }
  100% {
    transform: translateX(0);
  }
}

.message p {
  padding: 0 24px;
  font-size: 18px;
}

.message button {
  background: v-bind(messageButtonBackground);
  border: none;
  border-radius: 0 12px 12px 0;
  width: 42px;
  color: white;
}

.message button:hover {
  background: v-bind(messageButtonHoverBackground);
  cursor: pointer;
}
</style>
