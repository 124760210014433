<template>
  <div class="login-page-container">
    <div class="image-container">
      <img id="login-img" src="../assets/login.jpg" />
      <div class="logo-container">
        <img id="pecsmart-logo" src="../assets/logo.svg" />
      </div>
    </div>

    <div class="login-container">
      <form @submit.prevent="login">
        <div style="width: 100%;">
          <h1>Login</h1>
        </div>

        <input :style="input_style" type="text" name="username" placeholder="Nome de usuário" required/>
        <input :style="input_style" type="password" name="password" placeholder="Senha" required/>

        <button :class="button_state_class" type="submit">Entrar</button>
      </form>
    </div>

    <ResponseAlert :show="show_alert" @changeShow="showAlert" :success="success" :message="message" />
  </div>
</template>

<script lang="ts" setup>
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMainStore } from '@/store/main';
import { ref, Ref, onMounted } from "vue";
import router from '@/router';
import ResponseAlert from '@/components/ResponseAlert.vue';
import { useRegister } from '@/composables/form_behavior';
const { message, button_state_class, success, show_alert, showAlert, register } = useRegister()
const store = useMainStore()


// Methods
const login = async (event: any): Promise<void> => {
  try {
    await register(store.isUserAllowed, Object.fromEntries(new FormData(event.target)));

    router.push("/platform/dashboard");
  }
  catch(error) {
    console.log(error)
    return;
  }
}

// Data
const input_style: Ref<string> = ref("outline-color: var(--dark-green-pecsmart);")

onMounted(() => {window.localStorage.removeItem("Token")})
</script>

<style scoped>
.login-page-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.image-container {
  position: relative;
  width: 50%;
}

#login-img {
  height:100%;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.logo-container {
  left: 0; 
  top: 0; 
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center; 
  position: absolute;
}

#pecsmart-logo {
  height:10%;
}

.login-container {
  width: -webkit-fill-available;
  width: -moz-available;
  background: var(--dark-white);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: Helvetica;
}

.login-container h1 {
  font-weight: 100;
  margin: 24px 24px;
  color: var(--dark-green-pecsmart);
}

.login-container form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 50%;
  padding: 12px;
  border-radius: 12px;
}

.login-container form input{
  padding: 6px 24px;
  height: 48px;
  width: 100%;
  margin: 12px;
  font-size: 18px;
  box-sizing: border-box;
  border-radius: 12px;
  border: .5px solid var(--light-grey);
}

input:-webkit-autofill {
  -webkit-box-shadow:200px 200px 100px white inset; 
  box-shadow:200px 200px 100px white inset;
}

::placeholder {
  color: var(--light-grey);
}

@media (max-width: 1024px) {
  .image-container {
    display: none;
  }

  .login-container h1 {
    text-align: center;
  }
}

@media (max-width: 768px) {
  .register-button {
    width: 70%;
  }
}

@media(max-width: 640px) {

  .register-button {
    width: auto;
  }
}

@media(max-width: 420px) {
  .login-container form input {
    width: auto;
  }
}

</style>
