/* eslint-disable @typescript-eslint/no-explicit-any */
import { ref, Ref, computed, ComputedRef, watch } from "vue";

export function useRegister() {
  // Data
  const is_form_loading: Ref<boolean> = ref(false);
  const success: Ref<boolean> = ref(true);
  const show_alert: Ref<boolean> = ref(false);
  const message: Ref<string> = ref("");

  // Computed
  const button_state_class: ComputedRef<string> = computed(() => is_form_loading.value ? "register-button-disabled" : "register-button");

  // Methods
  const showAlert = (show: boolean): void => {show_alert.value = show}
  const register = async (action_function: any, form_data: any): Promise<void> => {
    try {
      if (button_state_class.value === "register-button-disable") return;
      is_form_loading.value = true

      await action_function(form_data)

      message.value = "Success"
      success.value = true
      if (show_alert.value) show_alert.value = false
      setTimeout(() => show_alert.value = true, 10)

      is_form_loading.value = false
    }
    catch(error: any) {
      const error_message = `${error}`.replace("Error: ", "")
      message.value = `${Object.values(JSON.parse(error_message))[0]}`
      success.value = false

      if (show_alert.value) show_alert.value = false
      setTimeout(() => show_alert.value = true, 10)
      
      is_form_loading.value = false
      throw Error(`${error}`)
    }
  }

  // Watch
  watch(is_form_loading, (new_is_form_loading) => document.body.style.cursor = new_is_form_loading ? "progress" : "default");

  return { message, button_state_class, is_form_loading, success, show_alert, showAlert, register };
}
